import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';

import defnav from './img/ico/blackico/home.svg';
import dark from './img/ico/blackico/book.svg';
import wallet from './img/ico/blackico/wallet-3.svg';
import user from './img/ico/blackico/user.svg';

import back from './img/ico/blackico/arrow-left.svg';
import notify from './img/appimg/welcome.gif';


const Wallethelp = () => {

    const [HomeActive, setHomeActive] = useState('inactive');
    const [UploadActive, setUploadActive] = useState('inactive');
    const [walletActive, setWalletActive] = useState('inactive');
    const [userActive, setUserActive] = useState('inactive');
    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const handleSupport = () => {

        window.MessageBirdChatWidget.toggleChat(true);
        window.MessageBirdChatWidget.show();
    }
    


    
    const homeactive = () => {

        setHomeActive('active');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const uploadactive = () => {

        setHomeActive('inactive');
        setUploadActive('active');
        setWalletActive('inactive');
        setUserActive('inactive');

        
    }


    const walletactive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('active');
        setUserActive('inactive');
    }


    const useractive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('active');
    }


    return ( 

        <div>

            <section className='topnav py-lg-3 px-lg-4 py-3 fixed-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 txt'>
                            <div className='text-start  d-flex align-items-center text-dark'>
                                <Link to="/wallet" rel="noreferrer"><img src={back} width="30" alt="" className="me-3"/></Link>
                                <h6 className="m-1 text-dark"> Earning with DotPedia 🎊</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <br/><br/><br/><br/>
         

        <div className="container col-lg-6">
            <div className="px-4 my-5 justify-content-center text-center pt-1">
                <div className="text-start">
                    <img src={notify} alt="DotPedia" className="img-fluid" width="200"/>
                    <h1 className="text-white display-5 mb-4 mt-lg-4 mt-3 col-lg-10">Earning Rewards</h1>
                    <p style={{color: "#a1a1a1"}} className="mt-3 mb-2 col-lg-9">On DotPedia, we have our mode of currency called <strong><b>Dots. </b></strong>
                    This was designed to ensure that everyone enjoys a seamless experience of rewards for every task done.</p>

                    <br/>

                    <p> 1 Dots = NGN10 </p>

                    <br/>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">On signup, we automatically credit everyone with at least <strong><b>10 Dots</b></strong> to get started.
                    This allows you explore DotPedia and understand how it works</p>

                    <br/>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">Whenever a user on DotPedia purchases your approved note, we'll credit your wallet automatically
                      with 2Dots. Which means that if 20 users purchases your note, your wallet will be credited automatically with - 2 x 20 = 40Dots.</p>

                    <br/>
                    
                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">For past questions without solutions, we'll credit your wallet automatically
                      with 2.5Dots. Which means that if 20 users purchases your past question, your wallet will be credited automatically with - 2.5 x 20 = 50Dots.</p>

                    <br/>
                      <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">For past questions with solutions, we'll credit your wallet automatically
                      with 5Dots. Which means that if 20 users purchases your past question, your wallet will be credited automatically with - by 5 x 20 = 100Dots.</p>

                    <br/><br/>

                    <h1 className="text-white display-5 mb-4 mt-lg-4 mt-3 col-lg-10">Wallet Funding</h1>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">You can fund your wallet for as low as <strong><b>10Dots. </b></strong>
                    Depending on the subscription plan you choose. You can also either choose to fund via;</p>

                    <br/>

                    <ul style={{color: "#a1a1a1"}}>
                        <li>Cash Transfer</li>
                        <li>Crpyo Currencies</li>
                        <li>DotFriends (<i>ur padii on DotPedia</i>)</li>
                        <li>Cardless Transfer or</li>
                        <li>Airtime</li>
                    </ul>

                    
                     <br/>

                    

                     <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">Incase you need help with any funding issue, you can always reach out to us via our chatbox 
                     in your profile tab.</p>


                     
                    <br/><br/>

                    <h1 className="text-white display-5 mb-4 mt-lg-4 mt-3 col-lg-10">Our Charges</h1>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">We charge for some certain activities you do on DotPedia. Below is a breakdown on how we charge;</p>

                    <br/>

                    <ul style={{color: "#a1a1a1"}}>
                        <li>Read Note: <b>3Dots (one-time pay)</b></li>
                        <li>Past Question: <b>4Dots (one-time pay)</b></li>
                        <li>PQ + solutions: <b>5Dots (one-time pay)</b></li>
                        <li>CBT Test <b>7Dot (Pay-As-You-Use)</b></li>
                    </ul>

                    <br/>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">When you read a note for the first time, we'll deduct 2Dots from your wallet. You only
                    make payment for this once.</p>
                    
                    <br/>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">Accessing past questions without solutions, we'll deduct 3Dots from your wallet. Once paid,
                    you have eternal access to the file</p>

                    <br/>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">Accessing past questions with solutions, we'll deduct 5Dots from your wallet. Once paid,
                    you have eternal access to the file</p>

                    <br/>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">To practice CBT Test, we'll deduct 7Dots from your wallet. Once paid, you can only access the 
                    test 3 times. After the third time, your wallet will be deducted of 7Dots</p>

                    <br/><br/>

                    <h1 className="text-white display-6 mb-4 mt-lg-4 mt-3 col-lg-10">Withdrawal/Transfer</h1>
                    
                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">You must have a minimum of <strong><b> 50Dots</b></strong> before you can withdraw into your local bank account</p>

                    <br/>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">You can make transfer to anyone using either their;</p>

                    <br/>

                    <ul style={{color: "#a1a1a1"}}>
                        <li>Account Number</li>
                        <li>Social Media Usernames</li>
                        <li>Phone Numbers</li>
                        <li>DotID or username</li>
                        <li>Email Address</li>
                    </ul>

                    <br/>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">Transfers to other banks are charged by 1% while transfers within DotPedia are FREE.</p>

                    <br/>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">Withdrawals are processed and settled into your account or the recipient bank instantly.</p>


                    <br/><br/>

                    <h1 className="text-white display-5 mb-4 mt-lg-4 mt-3 col-lg-10">Paying Bills</h1>
                
                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">Paying for utilities are no big deal. You can make payments for your airtime, internet subscription, spotify, Netflix, Dstv, GoTv and more.</p>

                    <br/><br/>

                    <button type="button" id="livechat-widget-opener" onClick={handleSupport} className='btn text-white mt-3 mb-3 bg-btn w-100 py-3 me-3 signupbtnn'>Chat with our support team</button>

                </div>
            
            </div>
        </div>


        <br/><br/><br/><br/><br/><br/>
                
                <section className='nav nav-bottom fixed-bottom'>
                    <div className='container'>
                        <div className='row justify-content-center text-center py-lg-3 px-lg-4 py-3'>
                            <div className='col-3'>
                                <Link to="/" rel="noreferrer"><img src={defnav} alt="" className={HomeActive} onClick={homeactive}/></Link>
                            </div>
                            <div className='col-3'>
                                <Link to="/tasks" rel="noreferrer"><img src={dark} alt="" className={UploadActive} onClick={uploadactive}/></Link>
                            </div>
    
                            <div className='col-3'>
                                <Link to="/wallet" rel="noreferrer"><img src={wallet} alt="" className={walletActive} onClick={walletactive}/></Link>
                            </div>
    
                            <div className='col-3'>
                                <Link to="/profile" rel="noreferrer"><img src={user} alt="" className={userActive} onClick={useractive}/></Link>
                            </div>
                        </div>
                    </div>         
                </section>
        </div>


     );
}
 
export default Wallethelp;
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Slide, ToastContainer, toast } from 'react-toastify';
import Confetti from 'react-confetti';
import Cookies from 'js-cookie';


import welcomesvg from './img/appimg/padi.png';
import getUserDeviceId from './userauth/userdeviceid';




const Signup = () => {

    const [useremail, setEmail] = useState('');
    const [deviceId, setDeviceId] = useState(getUserDeviceId);
    const [isConfettiActive, setIsConfettiActive] = useState(false);
    const [emailScreenIsActive, setEmailScreenIsActive] = useState(false);
    const [signupScreenIsActive, setSignupScreenIsActive] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {

        if(emailScreenIsActive === true) {

             toast.dismiss('acctsetup');

             document.getElementById("overlay").style.display = "block";

             toast.update('acctsetup', {
                 render: "Otp Sent to mail 🚀",
                 position: "top-center",
                 autoClose: 5000,
                 transition: Slide,
                 hideProgressBar: true,
                 theme: "info",
             });

             navigate("../userauth/emailsent");

        } else {

            if(signupScreenIsActive === true) {

                               
                setTimeout(() => {
                    navigate(".././");
                }, 3000); // wait for 4 seconds before executin

                document.getElementById("overlay").style.display = "block";

                toast.update('acctsetup', {
                    render: "Login Successful ✅",
                    position: "top-center",
                    autoClose: 4000,
                    transition: Slide,
                    hideProgressBar: true,
                    theme: "dark",
                });
                
            }
        }
    
    });


    const handleSubmit = (e) => {

        e.preventDefault();

        document.getElementById("overlay").style.display = "block";

        toast("We dey setup your account 🚀", {
            toastId: 'acctsetup',
            position: "top-center",
            autoClose: false,
            transition: Slide,
            hideProgressBar: true,
            theme: "info",
        });

        
        const userVerify = { 

            method: 'POST',
            headers: { 'Content-Type': 'application/json'},
            body: JSON.stringify({ email: useremail, device_id: deviceId })
        
        };


        Cookies.set('signupEmail', useremail);


        fetch('https://backend.dotpedia.com.ng/public/api/user-login', userVerify)
        .then(response => response.json())
        .then(data => {

            if (data.msg === 'activate device') {

                document.getElementById("overlay").style.display = "block";

                setEmailScreenIsActive(true);
        
            } else {
        
                if(data.msg === 'login successful') {

                    document.getElementById("overlay").style.display = "block";

                    const usernamer = data.userdetails.username;
                    const userNme = usernamer.charAt(0).toUpperCase() + usernamer.slice(1);

                    localStorage.setItem('usrTkn', JSON.stringify(data.userdetails.token));
                    localStorage.setItem('usrNme', JSON.stringify(userNme));   
                    setIsConfettiActive(true);    
                    setSignupScreenIsActive(true);
                }
        
            }
        })

        .catch(error => {

            document.getElementById("overlay").style.display = "none";

            toast.update('acctsetup', {
                render: "No/Bad internet connection 😭",
                position: "top-center",
                autoClose: 3000,
                transition: Slide,
                hideProgressBar: true,
                theme: "danger",
            });
        });
        

    }



    //login screen
    return ( 

       <div>

                <HelmetProvider>
                    <Helmet>
                         <title>Create an account</title>

                        <meta name="title" content="Create an account" />

                        <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate" />
                        <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />

                        <meta itemprop="name" content="Create an account" />

                        <meta name="twitter:title" content="Create an account" />
                        <meta name="twitter:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                       
                        <meta property="og:title" content="Create an account" />
                       
                        <meta property="og:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                    
                        <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                        <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate " />
                        <meta property="og:title" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                       
                    </Helmet>
                </HelmetProvider>

             <div id="overlay"></div>
            <section className="pt-1 mt-1 m-auto">

            
                <div className="container col-lg-6">
                    <div className="px-4 my-5 justify-content-center text-center pt-1">
                        <div className="text-start">
                            <img src={welcomesvg} alt="DotPedia" className="img-fluid" width="90"/>
                            <h1 className="text-white display-1 mb-4 mt-lg-4 mt-3 col-lg-10">Padii mi, aowfar?</h1>
                            <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">No need to remember a password or pin. We'll use your email address to perform the magic in seconds! 🚀</p>
                            <form method="post" onSubmit={handleSubmit}>
                                <div className="input-group mb-3">
                                    <input type="email" value={useremail} onChange={(e) => setEmail(e.target.value)} className="form-control form-control bg-white py-3 px-4 px-lg-5 mt-3"
                                    placeholder="Your email address over here" required/>
                                    <input type="text" value={deviceId} onChange={(e) => setDeviceId(e.target.value)} className="form-control form-control bg-white py-3 px-4 px-lg-5 mt-3"
                                    placeholder="Your email address over here" hidden required/>
                                    <button className="btn text-white mt-3 bg-btn w-100 py-3 signupbtnn">Submit</button>
                                    <ToastContainer/>
                                </div>
                            </form>
                        </div>
                    
                    </div>

                    {isConfettiActive && (
                        <Confetti
                        width={window.innerWidth}
                        height={window.innerHeight}
                        />
                    )}
                </div>

            </section>
       </div>
       
     );
}
 
export default Signup;
import { useEffect, useState  } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, TelegramShareButton } from 'react-share';
import { Link, useParams } from "react-router-dom";
import {Card} from 'react-bootstrap';
//import { Document, Page } from 'react-pdf';


import defnav from './img/ico/blackico/home.svg';
import dark from './img/ico/blackico/book.svg';
import wallet from './img/ico/blackico/wallet-3.svg';
import user from './img/ico/blackico/user.svg';
import back from './img/ico/blackico/arrow-left.svg';

import facebook from  './img/ico/social/Style=Original, Round=False.svg';
import twitter from './img/ico/social/Style=Original, Round=False2.svg';
import whatsapp from './img/ico/social/Style=Original, Round=False3.svg';
import telegram from './img/ico/social/Style=Original, Round=False (1).svg';



const ThisNote = () => {

    const [HomeActive, setHomeActive] = useState('inactive');
    const [UploadActive, setUploadActive] = useState('active');
    const [walletActive, setWalletActive] = useState('inactive');
    const [userActive, setUserActive] = useState('inactive');      
    const [getSpecificNoteTitle, setGetSpecificNoteTitle] = useState('');
    const [getSpecificNoteCoursecode, setGetSpecificNoteCoursecode] = useState('');  
    const [getSpecificNoteInst, setGetSpecificNoteInst] = useState('');
    const [getSpecificNoteLevel, setGetSpecificNoteLevl] = useState('');
    const [getSpecificNoteFac, setGetSpecificNoteFac] = useState('');
    const [getSpecificNoteSem, setGetSpecificNoteSem] = useState('');
    const [getSpecificNoteDep, setGetSpecificNoteDep] = useState('');
    //const [getSpecificNoteFile, setGetSpecificNoteFile] = useState('');

              
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const [isLoading, setIsLoading] = useState(true);

    const { noteid } = useParams();

   
   

    const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

   
    useEffect(() => {     

       

            fetch('https://backend.dotpedia.com.ng/public/api/pdf-notes?pdf_id='+noteid)
            .then(response => response.json())
            .then(data => {

                setIsLoading(false);

                setGetSpecificNoteTitle(data.pdf.title);
                setGetSpecificNoteCoursecode(data.pdf.course_code);
                setGetSpecificNoteInst(data.pdf.institution.short_name);
                setGetSpecificNoteLevl(data.pdf.level.name);
                setGetSpecificNoteFac(data.pdf.falculty.full_name);
                setGetSpecificNoteDep(data.pdf.department.full_name); 
                setGetSpecificNoteSem(data.pdf.semester);  
                //setGetSpecificNoteFile(data.pdf_path+data.pdf.file_link);
                

            })
            .catch(error => {
                console.log('error', error)
            });
    })


    const homeactive = () => { 

        setHomeActive('active');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const uploadactive = () => {

        setHomeActive('inactive');
        setUploadActive('active');
        setWalletActive('inactive');
        setUserActive('inactive');

        
    }


    const walletactive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('active');
        setUserActive('inactive');
    }


    const useractive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('active');
    }


    
    return ( 

        <div>

            <HelmetProvider>
                <Helmet>
                        <title>{getSpecificNoteTitle+" | DotPedia "}</title>
                        <meta name="title" content={getSpecificNoteTitle+" | DotPedia "} />      
                        <meta content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0" name="viewport" />

                        <meta name="keywords" content={getSpecificNoteCoursecode+" , "+getSpecificNoteInst+" , "+getSpecificNoteLevel+" Level , "+getSpecificNoteSem+" Semester , "+getSpecificNoteFac+" , "+getSpecificNoteDep} />

                        <meta itemprop="name" content={getSpecificNoteTitle+" | DotPedia "} />
                       
                        <meta name="twitter:title" content={getSpecificNoteTitle+" | DotPedia "} />
                        <meta name="twitter:description" content={getSpecificNoteCoursecode+" - "+getSpecificNoteInst+" - "+getSpecificNoteLevel+" Level - "+getSpecificNoteSem+" Semester - "+getSpecificNoteFac+" - "+getSpecificNoteDep} />
                      
                        <meta property="og:title" content={getSpecificNoteTitle+" | DotPedia "} />
                        <meta property="og:description" content={getSpecificNoteCoursecode+" - "+getSpecificNoteInst+" - "+getSpecificNoteLevel+" Level - "+getSpecificNoteSem+" Semester - "+getSpecificNoteFac+" - "+getSpecificNoteDep}/>
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={"https://usedotpedia.com.ng/thisnote/"+noteid} />
                        <meta property="og:description" content={getSpecificNoteCoursecode+" - "+getSpecificNoteInst+" - "+getSpecificNoteLevel+" Level - "+getSpecificNoteSem+" Semester - "+getSpecificNoteFac+" - "+getSpecificNoteDep} />
                        <meta property="og:site_name" content={getSpecificNoteTitle+" | DotPedia "} />
                </Helmet>
            </HelmetProvider>
       
                <section className='topnav py-lg-3 px-lg-4 py-3 fixed-top'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 txt'>
                                <div className='text-start  d-flex align-items-center text-dark'>
                                    <Link to="/tasks" rel="noreferrer"><img src={back} alt="" width="30" className="me-4"/></Link>
                                    <h6 className="m-1 text-dark">Preview/Share Note &nbsp; 📖 </h6>
                                </div>
                            </div>

                        
                        </div>
                    </div>
                </section>


                 <br/><br/><br/><br/>

                 { isLoading ? (

                    <p style={{fontSize: '12px'}} className="text-white fa-fade mt-5 px-3">Loading... Please wait</p>

                    ) : (
            
                    <div>

                        <section className="mt-2 wallet">
                            <div className="container">
                        
                                <Card>
                                    <Card.Body className="Justify-content-center text-center m-auto">
                                        <p className="text-center m-auto col-12 mb-2">{getSpecificNoteTitle}</p>
                                        <small> {getSpecificNoteCoursecode} &nbsp;|&nbsp; {getSpecificNoteInst} &nbsp;|&nbsp; {getSpecificNoteLevel} level</small><br/>
                                        <small>  {getSpecificNoteSem} Semester &nbsp;|&nbsp; {getSpecificNoteFac}</small>
                                    </Card.Body>
                                </Card>
                            </div>
                        </section>

                        <section className="mt-4 qtools">
                        <div className="container">
                            <div className="row justify-content-center text-center m-auto">
                        
                                <div className="col-3">
                                    <FacebookShareButton url={'https://usedotpedia.com.ng/thisnote/'+noteid} quote={getSpecificNoteCoursecode+" - "+getSpecificNoteInst+" - "+getSpecificNoteLevel+" Level - "+getSpecificNoteSem+" Semester - "+getSpecificNoteFac+" - "+getSpecificNoteDep} hashtag={"#"+getSpecificNoteCoursecode+", #doteightplus, #dotpedia"}>
                                    <Card className="Justify-content-center text-center m-auto">
                                        <Card.Body>
                                            <img src={facebook} alt="" />
                                        </Card.Body>
                                    </Card>
                                    </FacebookShareButton>
                                </div>
                        

                                <div className="col-3">
                                    {isMobile ? (
                                            
                                            <a target="_blank" rel="noreferrer" href={`https://twitter.com/intent/tweet?text=${"Hi guys, you can have access to "+getSpecificNoteTitle+" on DotPedia. Kindly use this link to have access - "}&url=${'https://usedotpedia.com.ng/thisnote/'+noteid}`}>
                                                <Card className="Justify-content-center text-center m-auto">
                                                    <Card.Body>
                                                    <img src={twitter} alt="" />
                                                    </Card.Body>
                                                </Card>
                                            </a>
                                        ) : (
                                    <TwitterShareButton url={'https://usedotpedia.com.ng/thisnote/'+noteid} title={"Hi guys, you can have access to "+getSpecificNoteTitle+" on DotPedia. Kindly use this link to have access - "}>
                                        <Card className="Justify-content-center text-center m-auto">
                                        <Card.Body>
                                        <img src={twitter} alt="" />
                                        </Card.Body>
                                    </Card>
                                    </TwitterShareButton>
                                    )}
                                </div>


                                <div className="col-3">
                                    <WhatsappShareButton url={'https://usedotpedia.com.ng/thisnote/'+noteid} title={"Hi guys, you can have access to "+getSpecificNoteTitle+" on DotPedia. Kindly use this link to have access"}>
                                        <Card className="Justify-content-center text-center m-auto">
                                            <Card.Body>
                                            <img src={whatsapp} alt="" />
                                            </Card.Body>
                                        </Card>
                                    </WhatsappShareButton>
                                </div>

                                <div className="col-3">
                                    <TelegramShareButton url={'https://usedotpedia.com.ng/thisnote/'+noteid}>
                                        <Card className="Justify-content-center text-center m-auto">
                                            <Card.Body>
                                            <img src={telegram} alt="" />
                                            </Card.Body>
                                        </Card>
                                    </TelegramShareButton>
                                </div>
                            </div>
                            
                        </div>
                        </section>

                        <section className="mt-4 dicover">
                            <div className="container">
                                    

                            </div>

                            <div className="text-center m-auto">

                                <button className="btn text-white mt-3 bg-btn w-50 py-3 signupbtnn">Add to My Library</button>
                            
                            </div>
                        </section>

                    </div>

                 )}
                
                <br/><br/><br/><br/><br/><br/>
                    
                <section className='nav nav-bottom fixed-bottom'>
                    <div className='container'>
                        <div className='row justify-content-center text-center py-lg-3 px-lg-4 py-3'>
                            <div className='col-3'>
                                <Link to="/" rel="noreferrer"><img src={defnav} alt="" className={HomeActive} onClick={homeactive}/></Link>
                            </div>
                            <div className='col-3'>
                                <Link to="/tasks" rel="noreferrer"><img src={dark} alt="" className={UploadActive} onClick={uploadactive}/></Link>
                            </div>

                            <div className='col-3'>
                                <Link to="/wallet" rel="noreferrer"><img src={wallet} alt="" className={walletActive} onClick={walletactive}/></Link>
                            </div>

                            <div className='col-3'>
                                <Link to="/profile" rel="noreferrer"><img src={user} alt="" className={userActive} onClick={useractive}/></Link>
                            </div>
                        </div>
                    </div>         
                </section>


        </div>
    );

}
 
export default ThisNote;
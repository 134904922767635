import { useState, useEffect } from 'react';
import {Card} from 'react-bootstrap';
import { Link, useNavigate} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import defnav from './img/ico/blackico/home.svg';
import dark from './img/ico/blackico/book.svg';
import wallet from './img/ico/blackico/wallet-3.svg';
import user from './img/ico/blackico/user.svg';
import back from './img/ico/blackico/arrow-right.svg';

import logout from './img/ico/blackico/logout.svg';
import profile from './img/appimg/padi.png';


const Profile = () => {

    const [HomeActive, setHomeActive] = useState('inactive');
    const [UploadActive, setUploadActive] = useState('inactive');
    const [walletActive, setWalletActive] = useState('inactive');
    const [userActive, setUserActive] = useState('active');        

    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleLogout = () => {

        localStorage.clear();

        navigate("../welcome");
    }

    const wallethelp = () => {

        navigate("../wallethelp");
    }

    const uploadhelp = () => {

        navigate("../uploadhelp");
    }

    
    const homeactive = () => {

        setHomeActive('active');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const uploadactive = () => {

        setHomeActive('inactive');
        setUploadActive('active');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const walletactive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('active');
        setUserActive('inactive');
    }


    const useractive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('active');
    }


    const chatwithuser = () => {
        window.SibConversations('show');
        window.SibConversations('openChat', true);
    }

    const userNme = JSON.parse(localStorage.getItem('usrNme'));

    return ( 

        <div>

                <HelmetProvider>

                    <Helmet>
                         <title>Profile and Settings</title>

                        <meta name="title" content="Profile and Settings" />

                        <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate" />
                        <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />

                        <meta itemprop="name" content="Profile and Settings" />

                        <meta name="twitter:title" content="Profile and Settings" />
                        <meta name="twitter:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                       
                        <meta property="og:title" content="Profile and Settings" />
                       
                        <meta property="og:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                    
                        <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                        <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate " />
                        <meta property="og:title" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                       
                    </Helmet>

                </HelmetProvider>


             <section className='topnav py-lg-3 px-lg-4 py-3 fixed-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-9 txt'>
                            <div className='text-start text-dark'>
                                <h6 className="m-1 text-dark">Your Profile &nbsp; 😎</h6>
                            </div>
                        </div>

                        <div className='col-2 me-1 mt-0'>
                            <div className='text-end text-dark'>
                                <img src={logout} alt="" width="23" onClick={handleLogout}/>
                            </div>
                        </div>
                    </div>
                </div>
             </section>


            <br/><br/><br/><br/>


            <section className='mt-2 profile'>
                <div className='text-center justify-content-center m-auto'>
                    <img src={profile} alt="" width="40"/>
                    <p className='mt-2 mb-0 fw-bold'>{userNme} <i className='fa fa-check-circle'></i></p>
                    <small>No user tag</small>
                    <br/><br/>

                    <button className="btn text-dark mt-0 mb-3 bg-btn py-2 signupbtnn"><small>Edit Profile</small></button>
                </div>
            </section>



            <section className="mt-3 wallet">
                <div className="container">
                    <Card>
                        <Card.Body className=''>
                            <div className='m-1 row'>
                                <div className='col-9'>
                                    <p className='mb-0 fw-bold'>Refer & Earn</p>
                                    <small>Invite your friends and get a bonus</small>
                                </div>
                                <div className='col-3 text-end'>
                                    <img src={back} alt="" width="20"  className='h-100'/>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card className='mt-3' onClick={chatwithuser}>
                        <Card.Body className=''>
                            <div className='m-1 row'>
                                <div className='col-9'>
                                    <p className='mb-0 fw-bold'>Help Center</p>
                                    <small >Have an issue? Speak to our team</small>
                                </div>
                                <div className='col-3 text-end'>
                                    <img src={back} alt="" width="20"  className='h-100'/>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card className='mt-3' onClick={wallethelp}>
                        <Card.Body className=''>
                            <div className='m-1 row'>
                                <div className='col-9'>
                                    <p className='mb-0 fw-bold'>Wallet Help</p>
                                    <small>Learn how to use your wallet</small>
                                </div>
                                <div className='col-3 text-end'>
                                    <img src={back} alt="" width="20"  className='h-100'/>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card className='mt-3' onClick={uploadhelp}>
                        <Card.Body className=''>
                            <div className='m-1 row'>
                                <div className='col-9'>
                                    <p className='mb-0 fw-bold'>Uploading Help</p>
                                    <small>Learn how to upload notes better</small>
                                </div>
                                <div className='col-3 text-end'>
                                    <img src={back} alt="" width="20"  className='h-100'/>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    <Card className='mt-3' onClick={handleLogout}>
                        <Card.Body className=''>
                            <div className='m-1 row'>
                                <div className='col-9'>
                                    <p className='mb-0 fw-bold'>Logout</p>
                                </div>
                                <div className='col-3 text-end'>
                                    <img src={back} alt="" width="20"  className='h-100'/>
                                </div>
                            </div>
                        </Card.Body>
                    </Card>

                    <div className='version text-center m-auto mt-4'>
                        <small>version 2.1.0</small>
                    </div>
                </div>
            </section>

        <br/><br/><br/><br/><br/><br/>
            
        <section className='nav nav-bottom fixed-bottom'>
                <div className='container'>
                    <div className='row justify-content-center text-center py-lg-3 px-lg-4 py-3'>
                        <div className='col-3'>
                            <Link to="/" rel="noreferrer"><img src={defnav} alt="" className={HomeActive} onClick={homeactive}/></Link>
                        </div>
                        <div className='col-3'>
                            <Link to="/tasks" rel="noreferrer"><img src={dark} alt="" className={UploadActive} onClick={uploadactive}/></Link>
                        </div>

                        <div className='col-3'>
                            <Link to="/wallet" rel="noreferrer"><img src={wallet} alt="" className={walletActive} onClick={walletactive}/></Link>
                        </div>

                        <div className='col-3'>
                            <Link to="/profile" rel="noreferrer"><img src={user} alt="" className={userActive} onClick={useractive}/></Link>
                        </div>
                    </div>
                </div>         
        </section>
    </div>

     );
}
 
export default Profile;
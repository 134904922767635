import { Link } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Card} from 'react-bootstrap';


import error404error from './img/appimg/success.gif';


import defnav from './img/ico/blackico/home.svg';
import dark from './img/ico/blackico/book.svg';
import wallet from './img/ico/blackico/wallet-3.svg';
import user from './img/ico/blackico/user.svg';

import back from './img/ico/blackico/arrow-left.svg';


const ApprovedNote = () => {

    const [HomeActive, setHomeActive] = useState('inactive');
    const [UploadActive, setUploadActive] = useState('inactive');
    const [walletActive, setWalletActive] = useState('active');
    const [userActive, setUserActive] = useState('inactive');        

    const [getNotes, setGetNotes] = useState([]);
    const [noteAvailable, setNoteAvailable] = useState(false);

    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
   
    

    useEffect(() => {     

        const usrTkn = JSON.parse(localStorage.getItem('usrTkn'));

        setTimeout(() => {
        setIsLoading(true);

            fetch('https://backend.dotpedia.com.ng/public/api/uploaded-notes?token='+usrTkn+'&type=approved')
            .then(response => response.json())
            .then(data => {

                setIsLoading(false);

                if(data.msg === 'pdfs not available') {

                    setNoteAvailable(true);

                } else {
                
                setNoteAvailable(false);
                setGetNotes( data.pdfs.map(item => ({ id: item.id, title: item.title, coursecode: item.course_code.toUpperCase(), level: item.level.name, fac: item.falculty.full_name, inst: item.institution.short_name, dept: item.department.short_name, sem: item.semester ? item.semester.charAt(0).toUpperCase() + item.semester.slice(1) : '' })));
                
                }
            })
            .catch(error => {
                console.log('error', error)
            });

        }, 1000);
       
    }, [])

    const homeactive = () => {

        setHomeActive('active');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const uploadactive = () => {

        setHomeActive('inactive');
        setUploadActive('active');
        setWalletActive('inactive');
        setUserActive('inactive');

        
    }


    const walletactive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('active');
        setUserActive('inactive');
    }


    const useractive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('active');
    }


    
    return ( 

        <div>


        <HelmetProvider>

            <Helmet>
                    <title>Your Approved Notes</title>

                <meta name="title" content="Your Approved Notes" />

                <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate" />
                <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />

                <meta itemprop="name" content="Your Approved Notes" />

                <meta name="twitter:title" content="Your Approved Notes" />
                <meta name="twitter:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                
                <meta property="og:title" content="Your Approved Notes" />
                
                <meta property="og:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
            
                <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate " />
                <meta property="og:title" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                
            </Helmet>

        </HelmetProvider>

           <section className='topnav py-lg-3 px-lg-4 py-3 fixed-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 txt'>
                            <div className='text-start  d-flex align-items-center text-dark'>
                                <Link to="/upload" rel="noreferrer"><img src={back} width="30" alt="" className="me-4"/></Link>
                                <h6 className="m-1 text-dark">Approved Notes ✅</h6>
                            </div>
                        </div>

                       
                    </div>
                </div>
            </section>

            <br/><br/><br/><br/>
            

            <section className="mt-3 dicover">
                <div className="container">

                { isLoading && <p style={{fontSize: '12px'}} className="text-white fa-fade mt-5 px-3">Loading... Please wait</p>}
                   
                   {getNotes.map(Notes => (
                    

                       
                            <Card className="mb-3 py-3 px-4" key={Notes.id}>
                        <Card.Body>
                            <div className="row">
                               
                                <div className="col-12 m-3">
                                    <p className="fw-bold mb-3">{Notes.title} </p>
                                    <small> {Notes.coursecode} &nbsp;|&nbsp; {Notes.inst} &nbsp; | &nbsp; {Notes.sem} Semester</small><br/>
                                    <small> {Notes.level} level &nbsp;|&nbsp; {Notes.fac}</small>
                                    <div className="row mt-3">
                                        <div className="col-12">
                                        <Link to={`/thisnote/${Notes.id}`} className="text-decoration-none" rel="noreferrer">
                                             <button className="btn text-white mt-3 me-2 bg-btn py-3 signupbtnn">Share / View</button></Link>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </Card.Body>
                        </Card>

                   ))} 


                { noteAvailable && <div className="px-4 my-5 justify-content-center text-center pt-1">
                    <div className="text-start">
                        <img src={error404error} alt="DotPedia" className="img-fluid" width="200"/>
                        <h1 className="text-white display-1 mb-4 mt-lg-4 mt-3 col-lg-10">Unavailable Approved Note(s) Yet!</h1>
                        <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">You don't have any approved note in your library yet. You'll be notified via mail if your notes is approved.</p>
                        <Link to="/upload" className="text-decoration-none" rel="noreferrer"><button className="btn text-white mt-3 bg-btn w-100 py-3 signupbtnn">Begin Uploading</button></Link> 
                    </div>
                </div>
                }

                </div>
            </section>


            
            <br/><br/><br/><br/><br/><br/>
                
            <section className='nav nav-bottom fixed-bottom'>
                <div className='container'>
                    <div className='row justify-content-center text-center py-lg-3 px-lg-4 py-3'>
                        <div className='col-3'>
                            <Link to="/" rel="noreferrer"><img src={defnav} alt="" className={HomeActive} onClick={homeactive}/></Link>
                        </div>
                        <div className='col-3'>
                            <Link to="/tasks" rel="noreferrer"><img src={dark} alt="" className={UploadActive} onClick={uploadactive}/></Link>
                        </div>

                        <div className='col-3'>
                            <Link to="/wallet" rel="noreferrer"><img src={wallet} alt="" className={walletActive} onClick={walletactive}/></Link>
                        </div>

                        <div className='col-3'>
                            <Link to="/profile" rel="noreferrer"><img src={user} alt="" className={userActive} onClick={useractive}/></Link>
                        </div>
                    </div>
                </div>         
            </section>
        </div>
    );

}
 
export default ApprovedNote;
import error404error from './img/appimg/success.gif';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';

import defnav from './img/ico/blackico/home.svg';
import dark from './img/ico/blackico/book.svg';
import wallet from './img/ico/blackico/wallet-3.svg';
import user from './img/ico/blackico/user.svg';


const UpdateSuccess = () => {

    const [HomeActive, setHomeActive] = useState('inactive');
    const [UploadActive, setUploadActive] = useState('inactive');
    const [walletActive, setWalletActive] = useState('inactive');
    const [userActive, setUserActive] = useState('inactive'); 


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    
    const homeactive = () => {

        setHomeActive('active');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const uploadactive = () => {

        setHomeActive('inactive');
        setUploadActive('active');
        setWalletActive('inactive');
        setUserActive('inactive');

        
    }


    const walletactive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('active');
        setUserActive('inactive');
    }


    const useractive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('active');
    }


    return ( 

        
        <div>

        <div className="container col-lg-6">
            <div className="px-4 my-5 justify-content-center text-center pt-1">
                <div className="text-start">
                    <img src={error404error} alt="DotPedia" className="img-fluid" width="300"/>
                    <h1 className="text-white display-1 mb-4 mt-lg-4 mt-3 col-lg-10">Verifying..</h1>
                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">Your file has been successfully updated. We will notify you by email regarding its approval or rejection.</p>
                    
                </div>

                <Link to="/approvednote" rel="noreferrer" className='text-decoration-none'><div className='d-flex mt-3'>
               <button className='btn text-white mt-3 mb-3 bg-btn w-100 py-3 me-3 signupbtnn'>Continue Editing</button>
                </div></Link>
            
            </div>

            
        </div>

        
        <br/><br/><br/><br/><br/><br/>
                
                <section className='nav nav-bottom fixed-bottom'>
                    <div className='container'>
                        <div className='row justify-content-center text-center py-lg-3 px-lg-4 py-3'>
                            <div className='col-3'>
                                <Link to="/" rel="noreferrer"><img src={defnav} alt="" className={HomeActive} onClick={homeactive}/></Link>
                            </div>
                            <div className='col-3'>
                                <Link to="/tasks" rel="noreferrer"><img src={dark} alt="" className={UploadActive} onClick={uploadactive}/></Link>
                            </div>
    
                            <div className='col-3'>
                                <Link to="/wallet" rel="noreferrer"><img src={wallet} alt="" className={walletActive} onClick={walletactive}/></Link>
                            </div>
    
                            <div className='col-3'>
                                <Link to="/profile" rel="noreferrer"><img src={user} alt="" className={userActive} onClick={useractive}/></Link>
                            </div>
                        </div>
                    </div>         
                </section>

        </div>


     );
}
 
export default UpdateSuccess;
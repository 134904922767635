import {useEffect, useState } from 'react';
import {Link} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Card} from 'react-bootstrap';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react';


import back from './img/ico/blackico/arrow-left.svg';


const AssignmentHelper = () => {


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);




    const API_KEY = "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";
    // "Explain things like you would to a 10 year old learning how to code."
    const systemMessage = { //  Explain things like you're talking to a software professional with 5 years of experience.
    "role": "system", "content": "Explain things like you would to a 100 level undergraduate in a university"
    }

   
    const [messages, setMessages] = useState([
        {
          message: "Hi, I'm Dotty! <br/>You can ask me anything",
          sentTime: "just now",
          sender: "Dotty"
        }
    ]);

    const [isTyping, setIsTyping] = useState(false);
  
    const handleSend = async (message) => {

        window.scrollTo(0,document.body.scrollHeight);

    
      const newMessage = {
        message,
        direction: 'outgoing',
        sender: "user"
      };
  
      const newMessages = [...messages, newMessage];
      
      setMessages(newMessages);
  
      // Initial system message to determine ChatGPT functionality
      // How it responds, how it talks, etc.
      setIsTyping(true);
      await processMessageToChatGPT(newMessages);
    };
  
    async function processMessageToChatGPT(chatMessages) { // messages is an array of messages
      // Format messages for chatGPT API
      // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
      // So we need to reformat
  
      let apiMessages = chatMessages.map((messageObject) => {
        let role = "";
        if (messageObject.sender === "ChatGPT") {
          role = "assistant";
        } else {
          role = "user";
        }
        return { role: role, content: messageObject.message}
      });
  
  
      // Get the request body set up with the model we plan to use
      // and the messages which we formatted above. We add a system message in the front to'
      // determine how we want chatGPT to act. 
      const apiRequestBody = {
        "model": "gpt-3.5-turbo",
        "messages": [
          systemMessage,  // The system message DEFINES the logic of our chatGPT
          ...apiMessages // The messages from our chat with ChatGPT
        ]
      }
  
      await fetch("https://api.openai.com/v1/chat/completions", 
      {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + API_KEY,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(apiRequestBody)
      }).then((data) => {
        return data.json();
      }).then((data) => {
        //console.log(data);
        window.scrollTo(0,document.body.scrollHeight);
        setMessages([...chatMessages, {
          message: data.choices[0].message.content,
          sender: "ChatGPT"
        }]);
        setIsTyping(false);
      });
    }


    return ( 

        <div>

            <HelmetProvider>
                
                <Helmet>
                        <title>Assignment Helper</title>

                    <meta name="title" content="Assignment Helper" />

                    <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate" />
                    <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />

                    <meta itemprop="name" content="Assignment Helper" />

                    <meta name="twitter:title" content="Assignment Helper" />
                    <meta name="twitter:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                    
                    <meta property="og:title" content="Assignment Helper" />
                    
                    <meta property="og:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                
                    <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                    <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate " />
                    <meta property="og:title" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                    
                </Helmet>

            </HelmetProvider>

            <div id="overlay"></div>

            <section className='topnav py-lg-3 px-lg-4 py-3 fixed-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 txt'>
                            <div className='text-start text-white d-flex align-items-center'>
                                <Link to="/tasks" rel="noreferrer"><img src={back} width="30" alt="" className='me-2' /></Link>
                                <h6 className="m-1 text-dark">Assignment Helper &nbsp;😎</h6>
                            </div>
                        </div>

                        
                    </div>
                </div>
            </section>

            <br/><br/><br/>


           
            <section className="mt-3 dicover">
                <div className="container">
                    <Card className="py-3 px-1">
                        <Card.Body>
                                 
                                <MessageList 
                                scrollBehavior="smooth" 
                                typingIndicator={isTyping ? <TypingIndicator content="Dotty is typing" /> : null}
                                >
                                {messages.map((message, i) => {
                                    //console.log(message)
                                    return <Message key={i} model={message} />
                                })}
                                </MessageList>

                        </Card.Body>
                    </Card>       
                </div>
            </section>


        
           
            <br/><br/><br/><br/><br/><br/>
                
            
                 <MessageInput className='px-3 py-4 chatr fixed-bottom' placeholder="Type message here" onSend={handleSend}/>        
              

           
         </div>

     );
}
 
export default AssignmentHelper;
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Card} from 'react-bootstrap';


import defnav from './img/ico/blackico/home.svg';
import dark from './img/ico/blackico/book.svg';
import wallet from './img/ico/blackico/wallet-3.svg';
import user from './img/ico/blackico/user.svg';
import info from './img/ico/blackico/element-equal.svg';

const Tasks = () => {

    const [HomeActive, setHomeActive] = useState('inactive');
    const [UploadActive, setUploadActive] = useState('active');
    const [walletActive, setWalletActive] = useState('inactive');
    const [userActive, setUserActive] = useState('inactive');
    const [searchText, setSearchText] = useState(''); 
    const [serachNote, setSearchNotes] = useState([]);
    
    
    const [getNotes, setGetNotes] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isSearching, setIsSearching] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    
    const homeactive = () => {

        setHomeActive('active');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const uploadactive = () => {

        setHomeActive('inactive');
        setUploadActive('active');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const walletactive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('active');
        setUserActive('inactive');
    }


    const useractive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('active');
    }


    useEffect(() => {     

        setTimeout(() => {
        setIsLoading(true);

        fetch('https://backend.dotpedia.com.ng/public/api/pdf-notes')
        .then(response => response.json())
        .then(data => {

            setIsLoading(false);

           
            setGetNotes(data.pdfs.map(item => ({ id: item.id, title: item.title, coursecode: item.course_code.toUpperCase(), level: item.level.name, inst: item.institution.short_name, dept: item.department.short_name, user: item.user.username })));
        })
        .catch(error => {
            console.log('error', error)
        });

    }, 1000);
       
    }, [])


    const handleSearch = (e) => {

        e.preventDefault();

        setIsSearching(false);
        setIsLoading(true);

        fetch("https://backend.dotpedia.com.ng/public/api/search-for-note?title="+searchText)
        .then(response => response.json())
        .then(data => {

            //console.log(data);
            setIsSearching(true);
            setIsLoading(false);
            setSearchNotes(data.pdf.map(srch => ({ id: srch.id, title: srch.title, coursecode: srch.course_code.toUpperCase(), level: srch.level.name, fac: srch.falculty.full_name, inst: srch.institution.short_name, dept: srch.department.short_name, sem: srch.semester ? srch.semester.charAt(0).toUpperCase() + srch.semester.slice(1) : '' })));
                
           
        })
        .catch(error => {

           console.log(error);
        });


    }


    return ( 

        <div>
            <HelmetProvider>
                <Helmet>
                         <title>Lecture Notes & Past Questions</title>

                        <meta name="title" content="Lecture Notes & Past Questions" />

                        <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate" />
                        <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />

                        <meta itemprop="name" content="Lecture Notes & Past Questions" />

                        <meta name="twitter:title" content="Lecture Notes & Past Questions" />
                        <meta name="twitter:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                       
                        <meta property="og:title" content="Lecture Notes & Past Questions" />
                       
                        <meta property="og:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                    
                        <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                        <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate " />
                        <meta property="og:title" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                       
                </Helmet>

            </HelmetProvider>

            <section className='topnav py-lg-3 px-lg-4 py-3 fixed-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-9 txt'>
                            <div className='text-start d-flex align-items-center text-dark'>
                                <h6 className="m-1 mt-1 text-dark"> Academic Materials &nbsp; 📖</h6>
                            </div>
                        </div>

                        <div className='col-2 me-1 mt-1'>
                            <div className='text-end text-dark'>
                            <Link to="/assignment" rel="noreferrer"><img src={info} alt="" width="21" className='fa-fade'/></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <br/><br/><br/><br/>

            <section className="qtools">
                <div className="container">
                    <div className="row justify-content-center text-center m-auto">

                            <form method="post" onSubmit={handleSearch}>
                                <div className="input-group mb-4">
                                    <input type="search" className="form-control form-control bg-white py-3 px-4 px-lg-5 mt-3"
                                        placeholder="search a note..." value={searchText} onChange={(e) => setSearchText(e.target.value)} />
                                    </div>
                            </form>

                        <div className="col-6">

                            <Link to="/assignment" rel="noreferrer" className='text-decoration-none'>
                                <Card className="justify-content-center text-center m-auto">
                                    <Card.Body>
                                        <small>Assignment Help</small>
                                    </Card.Body>
                                </Card>
                            </Link>

                        </div>


                       <div className="col-6">
                       <Link to="/pq" rel="noreferrer" className='text-decoration-none'> <Card className="Justify-content-center text-center m-auto">
                                <Card.Body>
                                <small>Past Question(s)</small>
                                </Card.Body>
                            </Card></Link>
                        </div>


                    </div>
                    { isLoading && <p style={{fontSize: '12px'}} className="text-white fa-fade mt-5 px-3">Loading... Please wait</p>}
                </div>
            </section>


            <section className="mt-4 dicover">
                <div className="container">

                   


                { isSearching ? (

                            <div>

                            {serachNote.map(Search => (

                            <Link to={`/thisnote/${Search.id}`} className="text-decoration-none" rel="noreferrer" key={Search.id}><Card className="mb-3 py-3 px-4">
                            <Card.Body>
                                <div className="row">
                                
                                    <div className="col-12 m-3">
                                        <p className="fw-bold mb-3">{Search.title} </p>
                                        <small> {Search.coursecode} &nbsp;|&nbsp; {Search.inst} &nbsp;|&nbsp; {Search.level} level</small><br/>
                                        <small> Uploaded by: <strong>{Search.user}</strong></small><br/>
                                        <button className="btn text-white mt-4 bg-btn w-100 py-3 signupbtnn">View Details</button>
                                    </div>
                                
                                </div>
                            </Card.Body>
                            </Card></Link>

                            ))} 

                            </div>


                    ) : (

                  
                    <div>

                        {getNotes.map(Notes => (

                            <Link to={`/thisnote/${Notes.id}`} className="text-decoration-none" rel="noreferrer" key={Notes.id}><Card className="mb-3 py-3 px-4">
                            <Card.Body>
                                <div className="row">
                                
                                    <div className="col-12 m-3">
                                        <p className="fw-bold mb-3">{Notes.title} </p>
                                        <small> {Notes.coursecode} &nbsp;|&nbsp; {Notes.inst} &nbsp;|&nbsp; {Notes.level} level</small><br/>
                                        <small> Uploaded by: <strong>{Notes.user}</strong></small><br/>
                                        <button className="btn text-white mt-4 bg-btn w-100 py-3 signupbtnn">View Details</button>
                                    </div>
                                
                                </div>
                            </Card.Body>
                            </Card></Link>                         

                        ))} 

                    </div>

                    )}

                    
                </div>
            </section>


            <br/><br/><br/><br/><br/><br/>
                
            <section className='nav nav-bottom fixed-bottom'>
                <div className='container'>
                    <div className='row justify-content-center text-center py-lg-3 px-lg-4 py-3'>
                        <div className='col-3'>
                            <Link to="/" rel="noreferrer"><img src={defnav} alt="" className={HomeActive} onClick={homeactive}/></Link>
                        </div>
                        <div className='col-3'>
                            <Link to="/tasks" rel="noreferrer"><img src={dark} alt="" className={UploadActive} onClick={uploadactive}/></Link>
                        </div>

                        <div className='col-3'>
                            <Link to="/wallet" rel="noreferrer"><img src={wallet} alt="" className={walletActive} onClick={walletactive}/></Link>
                        </div>

                        <div className='col-3'>
                            <Link to="/profile" rel="noreferrer"><img src={user} alt="" className={userActive} onClick={useractive}/></Link>
                        </div>
                    </div>
                </div>         
            </section>
        </div>
     );
}
 
export default Tasks;
import './css/style.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useEffect } from 'react';
import { Slide, ToastContainer, toast } from 'react-toastify';

import Signup from './welcome';
import Dashboard from './dashboard';
import Emailsent from './userauth/emailsent';
import Tasks from './tasks';
import Wallet from './wallet';
import Profile from './profile';
import Upload from './upload';
import UploadSuccess from './uploadsuccess';
import Error404 from './404';
import Wallethelp from './wallethelp';
import ApprovedNote from './approvednote';
import EditNote from './edit';
import ThisNote from './ThisNotote';
import UpdateSuccess from './updatesuccess';
import UploadHelp from './uploadhelp';
import RejectedNote from './rejectednote';
import AssignmentHelper from './assignment';

function App() {

  //const [isOnline, setIsOnline] = useState(navigator.onLine);


    const handleOnline = () => {
      
      toast.dismiss();
  
      toast("internet Restored 🚀", {
          position: "bottom-left",
          autoClose: 3000,
          transition: Slide,
          hideProgressBar: true,
          theme: "info",
      });


      document.getElementById("overlay").style.display = "none";

    }


    const handleOffline = () => {

      toast.dismiss();
  
      toast("No/Bad internet connection 😭", {
          position: "bottom-left",
          autoClose: 3000,
          transition: Slide,
          hideProgressBar: true,
          theme: "danger",
      });


      document.getElementById("overlay").style.display = "none";

    }


  
  useEffect(() => {
    //const handleOnline = () => setIsOnline(true);
    //const handleOffline = () => setIsOnline(false);0 

    window.SibConversations('hide');

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (

    <Router>
    <div className="App">
      <Routes>
      <Route path='/' element={<Dashboard/>}/>
      <Route path='/dashboard' element={<Dashboard/>}/>
      <Route path='/welcome' element={<Signup/>}/>
      <Route path='/userauth/emailsent' element={<Emailsent/>}/>
      <Route path='/tasks' element={<Tasks/>}/>
      <Route path='/wallet' element={<Wallet/>}/>
      <Route path='/profile' element={<Profile/>}/>
      <Route path='/upload' element={<Upload/>}/>
      <Route path='/uploadsuccess' element={<UploadSuccess/>}/>
      <Route path='/wallethelp' element={<Wallethelp/>}/>
      <Route path='/*' element={<Error404/>}/>
      <Route path='/approvednote' element={<ApprovedNote/>}/>
      <Route path='/rejectednote' element={<RejectedNote/>}/>
      <Route path='/edit/:noteid' element={<EditNote/>}/>
      <Route path='/thisnote/:noteid' element={<ThisNote/>}/>
      <Route path='/updatesuccess' element={<UpdateSuccess/>}/>
      <Route path='/Uploadhelp' element={<UploadHelp/>}/>
      <Route path='/assignment' element={<AssignmentHelper/>}/>
      </Routes>
     
      <ToastContainer/>
   
    </div>
    </Router>
  );


}

export default App;
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@popperjs/core/dist/cjs/popper.js';
import 'react-toastify/dist/ReactToastify.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-multi-carousel/lib/styles.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('./service-worker.js')
      .then(registration => {

        setInterval(() => {
          registration.update();
          console.debug('Checked for update...');
        }, 1000 * 60 * 5); 

        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
        }

        console.log('Service worker registered:', registration);
      })
      .catch(error => {
        console.error('Service worker registration failed:', error);
      });
  });
}

// get reference to element from the dom
const livechatButton = document.getElementById('livechat-widget-opener');
// add event listener to button
livechatButton?.addEventListener('click', () => {
    if (window.MessageBirdChatWidget) {
        window.MessageBirdChatWidget.toggleChat(true);
    }
})
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Card} from 'react-bootstrap';

import bell from './img/ico/blackico/send-2.svg';

import defnav from './img/ico/blackico/home.svg';
import dark from './img/ico/blackico/book.svg';
import wallet from './img/ico/blackico/wallet-3.svg';
import user from './img/ico/blackico/user.svg';


const Dashboard = () => {

    const [HomeActive, setHomeActive] = useState('active');
    const [UploadActive, setUploadActive] = useState('inactive');
    const [walletActive, setWalletActive] = useState('inactive');
    const [userActive, setUserActive] = useState('inactive');        

    const [getNotes, setGetNotes] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    
    const usrTkn = JSON.parse(localStorage.getItem('usrTkn'));
    
    const userNme = JSON.parse(localStorage.getItem('usrNme'));

    const navigate = useNavigate();    

    
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {

        if (usrTkn === '' || usrTkn === null) {

            navigate("./welcome");

        } else {

            verifyToken();
        }

    });

   
    const verifyToken = () => {

        fetch('https://backend.dotpedia.com.ng/public/api/profile?token='+usrTkn)
        .then(response => response.json())
        .then(data => {
            
            if(data.status === 'success') {

               //do nothing
            
            } else {
    
               navigate("./welcome");     
            }
            })
    
    }
   

    useEffect(() => {     

        setTimeout(() => {
        setIsLoading(true);

        fetch('https://backend.dotpedia.com.ng/public/api/pdf-notes')
        .then(response => response.json())
        .then(data => {

            setIsLoading(false);

            const limitedData = data.pdfs.slice(0, 10); // limit the array to the first 10 items
            setGetNotes(limitedData.map(item => ({ id: item.id, title: item.title, coursecode: item.course_code.toUpperCase(), level: item.level.name, inst: item.institution.short_name, dept: item.department.short_name, user: item.user.username })));
        })
        .catch(error => {
            console.log('error', error)
        });

    }, 1000);
       
    }, [])

   
   
    const homeactive = () => {

        setHomeActive('active');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const uploadactive = () => {

        setHomeActive('inactive');
        setUploadActive('active');
        setWalletActive('inactive');
        setUserActive('inactive');

        
    }


    const walletactive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('active');
        setUserActive('inactive');
    }


    const useractive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('active');
    }


    const chatwithuser = () => {
        window.SibConversations('show');
        window.SibConversations('openChat', true);
    }


    
    return ( 

        <div>
            <HelmetProvider>
                <Helmet>
                         <title>DotPedia - Africa's leading library for Academic Aid</title>

                        <meta name="title" content="DotPedia - Africa's leading library for Academic Aid" />

                        <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate" />
                        <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />

                        <meta itemprop="name" content="DotPedia - Africa's leading library for Academic Aid" />

                        <meta name="twitter:title" content="DotPedia - Africa's leading library for Academic Aid" />
                        <meta name="twitter:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                       
                        <meta property="og:title" content="DotPedia - Africa's leading library for Academic Aid" />
                       
                        <meta property="og:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                    
                        <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                        <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate " />
                        <meta property="og:title" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                       
                </Helmet>

            </HelmetProvider>

           <section className='topnav py-lg-3 px-lg-4 py-3 fixed-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-9 txt'>
                            <div className='text-start text-dark'>
                                <h6 className="m-1 text-dark">Hello {userNme} 👋</h6>
                            </div>
                        </div>

                        <div className='col-2 me-1 mt-0' onClick={chatwithuser}>
                            <div className='text-end text-dark fa-fade'>
                                <img src={bell} alt="" width="23"/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <br/><br/><br/><br/>


            <section className="mt-2 wallet">
                <div className="container">
                    <Card>
                        <Card.Body className="Justify-content-center d-flex align-items-center text-center m-auto">
                            <p className="Justify-content-center d-flex align-items-center text-center m-auto">Your library is currently empty</p>
                        </Card.Body>
                    </Card>
                </div>
            </section>

            <section className="mt-4 qtools">
                <div className="container">
                    <div className="row justify-content-center text-center m-auto">
                        <div className="col-4">
                        <Link to="/tasks" rel="noreferrer" className="text-decoration-none"> <Card className="Justify-content-center text-center m-auto">
                                <Card.Body>
                                    <small>Lecture Notes</small>
                                </Card.Body>
                            </Card></Link>
                        </div>


                        <div className="col-4">
                        <Link to="/task#pq" rel="noreferrer" className="text-decoration-none"><Card className="Justify-content-center text-center m-auto">
                                <Card.Body>
                                <small>Past Question</small>
                                </Card.Body>
                            </Card></Link>
                        </div>


                        <div className="col-4">
                            <Link to="/wallet" rel="noreferrer" className="text-decoration-none"><Card className="Justify-content-center text-center m-auto">
                                <Card.Body>
                                    <small>Earn Money</small>
                                </Card.Body>
                            </Card></Link>
                        </div>
                    </div>
                    { isLoading && <p style={{fontSize: '12px'}} className="text-white fa-fade mt-5 px-3">Loading... Please wait</p>}
                </div>
            </section>
            
            

            <section className="mt-4 dicover">
                <div className="container">

                   
                   {getNotes.map(Notes => (

                        <Link to={`/thisnote/${Notes.id}`} className="text-decoration-none" rel="noreferrer" key={Notes.id}><Card className="mb-3 py-3 px-4">
                        <Card.Body>
                            <div className="row">
                               
                                <div className="col-12 m-3">
                                    <p className="fw-bold mb-3">{Notes.title} </p>
                                    <small> {Notes.coursecode} &nbsp;|&nbsp; {Notes.inst} &nbsp;|&nbsp; {Notes.level} level</small><br/>
                                    <small> Uploaded by: <strong>{Notes.user}</strong></small><br/>
                                    <button className="btn text-white mt-4 bg-btn w-100 py-3 signupbtnn">View Details</button>
                                </div>
                            
                            </div>
                        </Card.Body>
                        </Card></Link>

                   ))} 

                   
                  <Link to='./tasks' className="text-decoration-none" rel="noreferrer" ><div className="text-center justify-content-center m-auto">
                    <button className="btn text-white mt-3 mb-3 bg-btn w-50 py-3 signupbtnn">View All</button>
                   </div></Link>
                    
                </div>
            </section>


            
            <br/><br/><br/><br/><br/><br/>
                
            <section className='nav nav-bottom fixed-bottom'>
                <div className='container'>
                    <div className='row justify-content-center text-center py-lg-3 px-lg-4 py-3'>
                        <div className='col-3'>
                            <Link to="/" rel="noreferrer"><img src={defnav} alt="" className={HomeActive} onClick={homeactive}/></Link>
                        </div>
                        <div className='col-3'>
                            <Link to="/tasks" rel="noreferrer"><img src={dark} alt="" className={UploadActive} onClick={uploadactive}/></Link>
                        </div>

                        <div className='col-3'>
                            <Link to="/wallet" rel="noreferrer"><img src={wallet} alt="" className={walletActive} onClick={walletactive}/></Link>
                        </div>

                        <div className='col-3'>
                            <Link to="/profile" rel="noreferrer"><img src={user} alt="" className={userActive} onClick={useractive}/></Link>
                        </div>
                    </div>
                </div>         
            </section>
        </div>
    );

}
 
export default Dashboard;
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Slide, ToastContainer, toast } from 'react-toastify';
import Confetti from 'react-confetti';
import Cookies from 'js-cookie';

import emailloader from '../img/appimg/newemailloader.gif';




const Emailsent = () => {

    const [isConfettiActive, setIsConfettiActive] = useState(false);
    const [issetSignupScreen, setSignupScreen] = useState(false);
    const [issetAppScreen, setAppScreen] = useState(false);
    const [usercode, setUserCode] = useState('');

    const navigate = useNavigate();


    useEffect(() => {

        if(issetSignupScreen === true) {

             navigate("../welcome");

        } else {

            if(issetAppScreen === true) {

                document.getElementById("overlay").style.display = "block";
               

                toast.update("emailsubmit", {
                    render: 'Login Successful ✅',
                    position: "top-center",
                    autoClose: 3000,
                    transition: Slide,
                    hideProgressBar: true,
                    theme: "dark",
                });

               
                setTimeout(() => {
                    navigate(".././");
                }, 3000); // wait for 4 seconds before executin


                
            }
        }
    
    });


    const handleVerify = (e) => {

            e.preventDefault();

            const emailSignup = Cookies.get('signupEmail');
                
                document.getElementById("overlay").style.display = "block";

                toast("Submitting .. 😎", {
                    toastId: 'emailsubmit',
                    position: "top-center",
                    autoClose: false,
                    transition: Slide,
                    hideProgressBar: true,
                    theme: "info",
                });

            const codeVerify = { 
                    
                method: 'POST',
                headers: { 'Content-Type': 'application/json'},
                body: JSON.stringify({ activation_token: usercode, email: emailSignup })
            
            };


            fetch('https://backend.dotpedia.com.ng/public/api/activate-device', codeVerify)
            .then(response => response.json())
            .then(data => {

                if(data.status === 'error') {

                    document.getElementById("overlay").style.display = "none";

                    toast.update('emailsubmit', {
                        render: "check that thing again abeg..🤕",
                        position: "top-center",
                        autoClose: 3000,
                        transition: Slide,
                        hideProgressBar: true,
                        theme: "danger",
                    });

                } else {
                if(data.msg === 'invalide activation token') {

                    document.getElementById("overlay").style.display = "none";

                    toast.update('emailsubmit', {
                        render: "OTP is invalid/expired 😭",
                        position: "top-center",
                        autoClose: 3000,
                        transition: Slide,
                        hideProgressBar: true,
                        theme: "danger",
                    });


                } else {

                    if(data.msg === 'login successful') {

                        const usernamer = data.userdetails.username;
                        const userNme = usernamer.charAt(0).toUpperCase() + usernamer.slice(1);

                        localStorage.setItem('usrTkn', JSON.stringify(data.userdetails.token));
                        localStorage.setItem('usrNme', JSON.stringify(userNme));

                        
                        setIsConfettiActive(true);
                        setAppScreen(true);
                    }
                }
            }
            })

            .catch(error => {

                document.getElementById("overlay").style.display = "none";

                toast.update("emailsubmit", {
                    render: "No/Bad internet connection 😭",
                    position: "top-center",
                    autoClose: 3000,
                    transition: Slide,
                    hideProgressBar: true,
                    theme: "danger",
                });
        });


    }

    const handleRefresh = () => {
        
        setSignupScreen(true);

    };


    return ( 


     <div>

        <div id="overlay"></div>

           <section className="pt-1 mt-1 m-auto" id="emailcheckscreen">
                <div className="container col-lg-6">


                    <div className="px-4 my-5 justify-content-center text-center pt-1">

                        <div className="text-start">
                        <img src={emailloader} className="img-fluid" alt="emailloader" width="150"/>
                            <h1 className="text-white display-4 mb-4 mt-lg-4 mt-3 col-lg-9 col-9">We've sent you an OTP! </h1>

                            <p style={{color:"#a1a1a1"}} className="mb-3 col-lg-9">Check your inbox and spam folders. Mail might take a while to deliver depending on your internet connection strength </p>
                            

                            <form method="post">
                                    <div className="input-group mb-2">
                                        <input type="text" value={usercode} onChange={(e) => setUserCode(e.target.value)} className="form-control form-control bg-white py-3 px-4 px-lg-5 mt-3"
                                        placeholder="Input your verification code" required/>
                                    </div>

                                    <button onClick={handleVerify} type="button" className="btn text-white mt-3 mb-3 bg-btn w-100 py-3 signupbtnn">Verify my account</button>
                                    <ToastContainer/>
                            </form>

                            
                            <p style={{color:"#a1a1a1"}} className="mb-3 otp justify-content-center text-center m-auto" onClick={handleRefresh}>Didn't get OTP? Resend OTP</p>

                        </div>
                        
                    </div>

                    {isConfettiActive && (
                        <Confetti
                        width={window.innerWidth}
                        height={window.innerHeight}
                        />
                    )}

                </div>
           

        </section>
     </div>
        
     );
}
 
export default Emailsent;
import { useState, useEffect } from 'react';
import {useNavigate, Link } from "react-router-dom";
import {Card} from 'react-bootstrap';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import defnav from './img/ico/blackico/home.svg';
import dark from './img/ico/blackico/book.svg';
import wallet from './img/ico/blackico/wallet-3.svg';
import user from './img/ico/blackico/user.svg';

import info from './img/ico/blackico/element-equal.svg';

import note from './img/ico/dashicons-book.svg';
import pqq from './img/ico/dashicons-media-text.svg';
import cbt from './img/ico/dashicons-editor-spellcheck.svg';
import cart from './img/ico/dashicons-cart.svg';
import ticket from './img/ico/dashicons-media-text.svg';
import project from './img/ico/dashicons-editor-outdent.svg';
import txtbook from './img/ico/dashicons-portfolio.svg';
import dma from './img/ico/dashicons-smiley.svg';

const Wallet = () => {

    const [HomeActive, setHomeActive] = useState('inactive');
    const [UploadActive, setUploadActive] = useState('inactive');
    const [walletActive, setWalletActive] = useState('active');
    const [userActive, setUserActive] = useState('inactive');
    const [walletBal, setWalletBal] = useState('');
    const nairaEqv  = walletBal * 10;
    
    const usrTkn = JSON.parse(localStorage.getItem('usrTkn'));


    const navigate = useNavigate();


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {

        if (usrTkn === '' || usrTkn === null) {

            navigate("./welcome");

        } else {

            verifyToken();
        }

    });

   
    const verifyToken = () => {

        fetch('https://backend.dotpedia.com.ng/public/api/profile?token='+usrTkn)
        .then(response => response.json())
        .then(data => {
            
            if(data.status === 'success') {

               //do nothing
            
            } else {
    
               navigate("./welcome");     
            }
            })
    
    }


    useEffect(() => {

        fetch("https://backend.dotpedia.com.ng/public/api/dot-balance?token="+usrTkn)
        .then(response => response.json())
        .then(data => {
            
            //console.log(data);
            setWalletBal(data.dotbalance);
           
        })
        .catch(error => {
            console.log(error);
        });

    }, [usrTkn]);
    
    const homeactive = () => {

        setHomeActive('active');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const uploadactive = () => {

        setHomeActive('inactive');
        setUploadActive('active');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const walletactive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('active');
        setUserActive('inactive');
    }


    const useractive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('active');
    }


    return ( 

        <div>
                <HelmetProvider>
                    <Helmet>
                         <title>Tasks and Earnings</title>

                        <meta name="title" content="Tasks and Earnings" />

                        <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate" />
                        <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />

                        <meta itemprop="name" content="Tasks and Earnings" />

                        <meta name="twitter:title" content="Tasks and Earnings" />
                        <meta name="twitter:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                       
                        <meta property="og:title" content="Tasks and Earnings" />
                       
                        <meta property="og:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                    
                        <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                        <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate " />
                        <meta property="og:title" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                       
                     </Helmet>

                </HelmetProvider>

            <section className='topnav py-lg-3 px-lg-4 py-3 fixed-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-9 txt'>
                            <div className='text-start text-white d-flex align-items-center'>
                                <h6 className="m-1 text-dark">  Tasks & Earnings &nbsp;💸</h6>
                            </div>
                        </div>

                        <div className='col-2 me-1 mt-0'>
                            <div className='text-end text-dark'>
                            <Link to="/wallethelp" rel="noreferrer"><img src={info} alt="" width="21" className='fa-fade'/></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <br/><br/><br/><br/>

            <section className="mt-2 wallet">
                <div className="container">
                    <Card>
                        <Card.Body className="Justify-content-center d-flex align-items-center text-center m-auto">
                            <p className="Justify-content-center d-flex align-items-center text-center m-auto"><span> {walletBal} Dots ~ ₦{nairaEqv} </span></p>
                        </Card.Body>
                    </Card>
                </div>
            </section>


            <section className="mt-4 qtools">
                <div className="container">
                    <div className="row justify-content-center text-center m-auto">
                        <div className="col-4">
                        <Link to="/fund" rel="noreferrer" className='text-decoration-none'><Card className="Justify-content-center text-center m-auto">
                                <Card.Body>
                                    <small>History</small>
                                </Card.Body>
                            </Card></Link>
                        </div>


                        <div className="col-4">
                        <Link to="/transfer" rel="noreferrer" className='text-decoration-none'> <Card className="Justify-content-center text-center m-auto">
                                <Card.Body>
                                <small>Fund</small>
                                </Card.Body>
                            </Card></Link>
                        </div>


                        <div className="col-4">
                        <Link to="/bills" rel="noreferrer" className='text-decoration-none'><Card className="Justify-content-center text-center m-auto">
                                <Card.Body>
                                    <small>Cash Out</small>
                                </Card.Body>
                            </Card></Link>
                        </div>
                    </div>
                </div>
            </section>


            <section className="mt-4 dicover">
                <div className="container">
                   
                    <Link to="/upload" rel="noreferrer" className="text-decoration-none">
                        <Card className="mb-3 py-3 px-4">
                            <Card.Body>
                                <div className="row">
                                    <div className="col-3">
                                        <img src={note} alt="" className='img-fluid h-100'/>
                                    </div>
                                    <div className="col-9">
                                        <p className="fw-bold mb-0">Upload Lecture Notes</p>
                                        <small>Earn points by uploading neat lecture notes on DotPedia</small>
                                    </div>
                                
                                </div>
                            </Card.Body>
                        </Card>
                    </Link>

                    <Link to="/uploadpq" rel="noreferrer" className="text-decoration-none">
                    <Card className="mb-3 py-3 px-4">
                        <Card.Body>
                            <div className="row">
                                <div className="col-3">
                                    <img src={pqq} alt="" className='img-fluid h-100'/>
                                </div>
                                <div className="col-9">
                                    <p className="fw-bold mb-0">Upload Past Questions</p>
                                    <small>Turn your written exam booklet into past questions for users</small>
                                </div>
                               
                            </div>
                        </Card.Body>
                    </Card>
                    </Link>

                    <Link to="/createcbt" rel="noreferrer" className="text-decoration-none">
                    <Card className="mb-3 py-3 px-4">
                        <Card.Body>
                            <div className="row">
                                <div className="col-3">
                                    <img src={cbt} alt="" className='img-fluid h-100'/>
                                </div>
                                <div className="col-9">
                                    <p className="fw-bold mb-0">Create CBT Test</p>
                                    <small>Get paid when users take your CBT Test for exam practice</small>
                                </div>
                               
                            </div>
                        </Card.Body>
                    </Card>
                    </Link>

                    <Card className="mb-3 py-3 px-4" hidden>
                        <Card.Body>
                            <div className="row">
                                <div className="col-3">
                                    <img src={dma} alt="" className='img-fluid h-100'/>
                                </div>
                                <div className="col-9">
                                    <p className="fw-bold mb-0">Do My Assignment</p>
                                    <small>Get paid for helping users to do their assignment or exercise</small>
                                </div>
                               
                            </div>
                        </Card.Body>
                    </Card>

                    <Card className="mb-3 py-3 px-4" hidden>
                        <Card.Body>
                            <div className="row">
                                <div className="col-3">
                                    <img src={txtbook} alt="" className='img-fluid h-100'/>
                                </div>
                                <div className="col-9">
                                    <p className="fw-bold mb-0">Sell Textbooks</p>
                                    <small>Sell your textbooks and other academic materials to students</small>
                                </div>
                               
                            </div>
                        </Card.Body>
                    </Card>

                    <Card className="mb-3 py-3 px-4" hidden>
                        <Card.Body>
                            <div className="row">
                                <div className="col-3">
                                    <img src={project} alt="" className='img-fluid h-100'/>
                                </div>
                                <div className="col-9">
                                    <p className="fw-bold mb-0">Project Write-ups</p>
                                    <small>Write academic research papers for final year students</small>
                                </div>
                               
                            </div>
                        </Card.Body>
                    </Card>

                    <Card className="mb-3 py-3 px-4" hidden>
                        <Card.Body>
                            <div className="row">
                                <div className="col-3">
                                    <img src={ticket} alt="" className='img-fluid h-100'/>
                                </div>
                                <div className="col-9">
                                    <p className="fw-bold mb-0">Create Class Tickets</p>
                                    <small>Create and sell your class tickets on DotPedia for users access</small>
                                </div>
                               
                            </div>
                        </Card.Body>
                    </Card>

                    <Card className="mb-3 py-3 px-4" hidden>
                        <Card.Body>
                            <div className="row">
                                <div className="col-3">
                                    <img src={cart} alt="" className='img-fluid h-100'/>
                                </div>
                                <div className="col-9">
                                    <p className="fw-bold mb-0">Sell in our MarketPlace</p>
                                    <small>Create and sell your class tickets on DotPedia for users access</small>
                                </div>
                               
                            </div>
                        </Card.Body>
                    </Card>
                                    
                </div>
            </section>

            <br/><br/><br/><br/><br/><br/>
                
            <section className='nav nav-bottom fixed-bottom'>
                <div className='container'>
                    <div className='row justify-content-center text-center py-lg-3 px-lg-4 py-3'>
                        <div className='col-3'>
                            <Link to="/" rel="noreferrer"><img src={defnav} alt="" className={HomeActive} onClick={homeactive}/></Link>
                        </div>
                        <div className='col-3'>
                            <Link to="/tasks" rel="noreferrer"><img src={dark} alt="" className={UploadActive} onClick={uploadactive}/></Link>
                        </div>

                        <div className='col-3'>
                            <Link to="/wallet" rel="noreferrer"><img src={wallet} alt="" className={walletActive} onClick={walletactive}/></Link>
                        </div>

                        <div className='col-3'>
                            <Link to="/profile" rel="noreferrer"><img src={user} alt="" className={userActive} onClick={useractive}/></Link>
                        </div>
                    </div>
                </div>         
            </section>
        </div>

     );
}
 
export default Wallet;
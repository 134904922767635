import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';

import defnav from './img/ico/blackico/home.svg';
import dark from './img/ico/blackico/book.svg';
import wallet from './img/ico/blackico/wallet-3.svg';
import user from './img/ico/blackico/user.svg';

import back from './img/ico/blackico/arrow-left.svg';
import notify from './img/appimg/welcome.gif';


const UploadHelp = () => {

    const [HomeActive, setHomeActive] = useState('inactive');
    const [UploadActive, setUploadActive] = useState('inactive');
    const [walletActive, setWalletActive] = useState('inactive');
    const [userActive, setUserActive] = useState('inactive'); 

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    
    const homeactive = () => {

        setHomeActive('active');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const uploadactive = () => {

        setHomeActive('inactive');
        setUploadActive('active');
        setWalletActive('inactive');
        setUserActive('inactive');

        
    }


    const walletactive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('active');
        setUserActive('inactive');
    }


    const useractive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('active');
    }


    return ( 

        <div>

            <section className='topnav py-lg-3 px-lg-4 py-3 fixed-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='text-start d-flex align-items-center text-dark'>
                                <Link to="/upload" rel="noreferrer"><img src={back} alt="" width="30" className="txt me-3"/></Link>
                                <h6 className="m-2 text-dark"> Uploading Notes on DotPedia &nbsp;✅</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <br/><br/><br/><br/>
         

        <div className="container col-lg-6">
            <div className="px-4 my-5 justify-content-center text-center pt-1">
                <div className="text-start">
                    <img src={notify} alt="DotPedia" className="img-fluid" width="200"/>
                    <h1 className="text-white display-5 mb-4 mt-lg-4 mt-3 col-lg-10">Rules for uploading</h1>
                    
                    <p style={{color: "#a1a1a1"}} className="mt-3 mb-2 col-lg-9">Always ensure that your note(s) are neat, clear and are in in PDF format.</p>

                    <br/>

                    <p style={{color: "#a1a1a1"}} className="mt-3 mb-2 col-lg-9">Always write your course code in capital letters with a space between the letters and figures
                    for example, CPE 101. Never upload your notes with <s>cpe101</s> or <s>cpe 101</s> or <s>cpe 101.2</s>.
                    </p>
                
                    <br/><br/>

                    <h1 className="text-white display-5 mb-4 mt-lg-4 mt-3 col-lg-10">Note Approval</h1>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">Once you upload any note, we begin to review those note to
                    ensure that they follow the above stated guidelines.  </p>

                    <br/>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">
                    Note(s) are reviewed every 24-48 hours after upload.
                    Below are what we check for before approving any note;</p>

                    <br/>

                    <ul style={{color: "#a1a1a1"}}>
                        <li>Neatness of the note(s)</li>
                        <li>How clear the note(s) are</li>
                        <li>Note(s) matches the course title/code</li>
                        <li>Note(s) have not been uploaded before</li>
                    </ul>

                    
                     <br/>

                     <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">if your note is approved or rejected,
                      we'll notify you via email or your registered WhatsApp number.</p>

                      <br/>

                      
                      <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">Whenever a user on DotPedia purchases your note, we will credit your wallet automatically
                      with 2Dots. Which means that if 20 users purchases your note, we will credit your wallet automatically by 2 x 20 = 40Dots </p>


                     
                    <br/><br/>

                    <h1 className="text-white display-5 mb-4 mt-lg-4 mt-3 col-lg-10">Editing your note</h1>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">You can only edit your note once it is approved. To edit your note, click on the <b>Approved </b>
                    button on the "upload note" screen.</p>

                    <br/>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">Choose from your approved notes the one you wish to edit. Click on the edit button 
                    to edit that specific note. Once you are done editing, you can click on the submit button. </p>

                    <br/>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">To effect your changes, your edit is subjected to review between 24hours - 48 hours in order
                    to review if your edits followed our guidelines. </p>

                               
                    <br/><br/>

                    <h1 className="text-white display-5 mb-4 mt-lg-4 mt-3 col-lg-10">Deleting Note</h1>
                    
                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">You can delete your note by clicking on the edit/delete button in the approved note screen. This process
                    is irreversible</p>
                  

                    <br/>

                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">Your note will still be accessible to other users who have previously bought the note but will be made unavailable to new 
                    users</p>

                   <br/><br/>

                   <Link to="/upload" rel="noreferrer" className="text-decoration-none"><button className='btn text-white mt-3 mb-3 bg-btn w-100 py-3 me-3 signupbtnn'>Start Uploading</button></Link> 

                </div>
            
            </div>
        </div>


        <br/><br/><br/><br/><br/><br/>
                
                <section className='nav nav-bottom fixed-bottom'>
                    <div className='container'>
                        <div className='row justify-content-center text-center py-lg-3 px-lg-4 py-3'>
                            <div className='col-3'>
                                <Link to="/" rel="noreferrer"><img src={defnav} alt="" className={HomeActive} onClick={homeactive}/></Link>
                            </div>
                            <div className='col-3'>
                                <Link to="/tasks" rel="noreferrer"><img src={dark} alt="" className={UploadActive} onClick={uploadactive}/></Link>
                            </div>
    
                            <div className='col-3'>
                                <Link to="/wallet" rel="noreferrer"><img src={wallet} alt="" className={walletActive} onClick={walletactive}/></Link>
                            </div>
    
                            <div className='col-3'>
                                <Link to="/profile" rel="noreferrer"><img src={user} alt="" className={userActive} onClick={useractive}/></Link>
                            </div>
                        </div>
                    </div>         
                </section>
        </div>


     );
}
 
export default UploadHelp;
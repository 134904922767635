import {useEffect, useState } from 'react';
import {useNavigate, Link} from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Card} from 'react-bootstrap';
import { Slide, ToastContainer, toast } from 'react-toastify';
import { PopupButton  } from '@typeform/embed-react'

import defnav from './img/ico/blackico/home.svg';
import dark from './img/ico/blackico/book.svg';
import wallet from './img/ico/blackico/wallet-3.svg';
import user from './img/ico/blackico/user.svg';
import info from './img/ico/blackico/element-equal.svg';


import back from './img/ico/blackico/arrow-left.svg';


const Upload = () => {

    const [HomeActive, setHomeActive] = useState('inactive');
    const [UploadActive, setUploadActive] = useState('inactive');
    const [walletActive, setWalletActive] = useState('active');
    const [userActive, setUserActive] = useState('inactive');  

    const usrTkn = JSON.parse(localStorage.getItem('usrTkn'));


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {

        if (usrTkn === '' || usrTkn === null) {

            navigate("./welcome");

        } else {

            verifyToken();
        }

    });

   
    const verifyToken = () => {

        fetch('https://backend.dotpedia.com.ng/public/api/profile?token='+usrTkn)
        .then(response => response.json())
        .then(data => {
            
            if(data.status === 'success') {

               //do nothing
            
            } else {
    
               navigate("./welcome");     
            }
            })
    
    }

    
       
    const homeactive = () => {

        setHomeActive('active');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const uploadactive = () => {

        setHomeActive('inactive');
        setUploadActive('active');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const walletactive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('active');
        setUserActive('inactive');
    }


    const useractive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('active');
    }


    const [getLevels, setGetLevels] = useState([]);
    const [selectedLevels, setSelectedLevels] = useState('');


    const [getInst, setGetInst] = useState([]);
    const [selectedInst, setSelectedInst] = useState('');


    const [getFac, setGetFac] = useState([]);
    const [selectedFac, setSelectedFac] = useState('');


    const [getDept, setGetDept] = useState([]);
    const [selectedDept, setSelectedDept] = useState('');

    const [courseCode, setCourseCode] = useState('');
    const [courseTitle, setCourseTitle] = useState('');
    const [courseSem, setCourseSem] = useState('');
    const [courseFile, setCourseFile] = useState(null);

    const [approvedNote, setapprovedNote] = useState(0);


    const [uploadSuccess, setUploadSuccess] = useState(false); 


    const navigate = useNavigate();
    
    
    useEffect(() => {

       
        fetch('https://backend.dotpedia.com.ng/public/api/levels')
        .then(response => response.json())
        .then(data => {
            
            setGetLevels(data.msg.map(item => ({ id: item.id, name: item.name })));
        })
        .catch(error => console.error(error));




        fetch('https://backend.dotpedia.com.ng/public/api/institutions')
        .then(response => response.json())
        .then(data => { 
            
            setGetInst(data.msg.map(item => ({ id: item.id, name: item.short_name })));
        })
        .catch(error => console.error(error));



        fetch('https://backend.dotpedia.com.ng/public/api/faculties')
        .then(response => response.json())
        .then(data => {
            
            setGetFac(data.msg.map(item => ({ id: item.id, name: item.full_name })));
        })
        .catch(error => console.error(error));




        fetch('https://backend.dotpedia.com.ng/public/api/departments')
        .then(response => response.json())
        .then(data => {
            
            setGetDept(data.msg.map(item => ({ id: item.id, name: item.full_name, deptcode: item.short_name })));
        })
        .catch(error => console.error(error));


        
    }, []);



    useEffect(() => {

        
        fetch('https://backend.dotpedia.com.ng/public/api/uploaded-notes?token='+usrTkn+'&type=approved')
        .then(response => response.json())
        .then(data => {
            
            if(data.status === 'error' || data.msg === 'pdfs not available') {

                setapprovedNote(0);

            } else {

                setapprovedNote(data.number_of_notes);

            }
        })
        .catch(error => console.error(error));


        if(uploadSuccess === true) {

            toast.update("uploader", {
                render: "Upload Successful 🚀",
                position: "top-center",
                autoClose: 4000,
                transition: Slide,
                hideProgressBar: true,
                theme: "dark",
            });
            
            navigate("../uploadsuccess");
        }
    })



    const uploadNotes = (e) => {

        e.preventDefault();

        document.getElementById("overlay").style.display = "block";

        toast("Uploading 🚀", {
            toastId: 'uploader',
            position: "top-center",
            autoClose: false,
            transition: Slide,
            hideProgressBar: false,
            theme: "info",
        });


        if(selectedLevels === '' || selectedLevels === null) {

            document.getElementById("overlay").style.display = "none";

            toast.update("uploader", {
                render: "You never select your level nah 👀",
                position: "top-center",
                autoClose: 3000,
                transition: Slide,
                hideProgressBar: false,
                theme: "info",
            });

        } else {

            if(selectedInst === '' || selectedInst === null) {

                document.getElementById("overlay").style.display = "none";

                toast.update("uploader", {
                    render: "Bros, your school no fit dey empty 👀",
                    position: "top-center",
                    autoClose: 3000,
                    transition: Slide,
                    hideProgressBar: false,
                    theme: "info",
                });

            } else {

                if(selectedFac === '' || selectedFac === null) {

                    document.getElementById("overlay").style.display = "none";

                    toast.update("uploader", {
                        render: "No faculty? You dey whyne me? 👀",
                        position: "top-center",
                        autoClose: 3000,
                        transition: Slide,
                        hideProgressBar: false,
                        theme: "info",
                    });

                } else {

                    if(selectedDept === '' || selectedDept === null) {

                        document.getElementById("overlay").style.display = "none";

                        toast.update("uploader", {
                            render: "Abeg, wetin be your department? 👀",
                            position: "top-center",
                            autoClose: 3000,
                            transition: Slide,
                            hideProgressBar: false,
                            theme: "info",
                        });

                    } else {

                        if(courseSem === '' || courseSem === null) {

                            document.getElementById("overlay").style.display = "none";

                            toast.update("uploader", {
                                render: "The semester nko? 👀",
                                position: "top-center",
                                autoClose: 3000,
                                transition: Slide,
                                hideProgressBar: false,
                                theme: "info",
                            });
    


                        } else {


                            const formdata = new FormData();
                            formdata.append("token", usrTkn);
                            formdata.append("pdf", courseFile);
                            formdata.append("title", courseTitle);
                            formdata.append("level_id", selectedLevels);
                            formdata.append("department_id", selectedDept);
                            formdata.append("type", "1");
                            formdata.append("course_code", courseCode);
                            formdata.append("institution_id", selectedInst);
                            formdata.append("semester", courseSem);


                            const requestOptions = {
                                method: 'POST',
                                body: formdata
                            };


                            fetch("https://backend.dotpedia.com.ng/public/api/upload-note", requestOptions)
                            .then(response => response.json())
                            .then(data => {

                                
                                if(data.msg === 'Invalid File Extension') {

                                    document.getElementById("overlay").style.display = "none";

                                    toast("uploader", {
                                        render: "We only accept '.pdf' files 👀",
                                        position: "top-center",
                                        autoClose: 3000,
                                        transition: Slide,
                                        hideProgressBar: true,
                                        theme: "info",
                                    });

                                } else {

                                    if(data.msg === 'Note Successfully Upload') {
                                        
                                        setUploadSuccess(true);

                                    }
                                }
                            })
                            .catch(error => {

                                document.getElementById("overlay").style.display = "none";

                                toast.update("uploader", {
                                    render: "No/Bad internet connection 😭",
                                    position: "top-center",
                                    autoClose: 3000,
                                    transition: Slide,
                                    hideProgressBar: true,
                                    theme: "danger",
                                });
                            });


                        }

                    }
                }
            }
        }

       
    }


    return ( 

        <div>

            <HelmetProvider>
                
                <Helmet>
                        <title>Upload Lecture Notes</title>

                    <meta name="title" content="Upload Lecture Notes" />

                    <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate" />
                    <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />

                    <meta itemprop="name" content="Upload Lecture Notes" />

                    <meta name="twitter:title" content="Upload Lecture Notes" />
                    <meta name="twitter:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                    
                    <meta property="og:title" content="Upload Lecture Notes" />
                    
                    <meta property="og:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                
                    <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                    <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate " />
                    <meta property="og:title" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                    
                </Helmet>

            </HelmetProvider>

            <div id="overlay"></div>

            <section className='topnav py-lg-3 px-lg-4 py-3 fixed-top'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-9 txt'>
                            <div className='text-start text-white d-flex align-items-center'>
                                <Link to="/wallet" rel="noreferrer"><img src={back} width="30" alt="" className='me-2' /></Link>
                                <h6 className="m-1 text-dark">Upload Lecture Notes &nbsp;📝</h6>
                            </div>
                        </div>

                        <div className='col-2 me-1 mt-1'>
                            <div className='text-end text-dark'>
                            <Link to="/uploadhelp" rel="noreferrer"><img src={info} alt="" width="21" className='fa-fade'/></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <br/><br/><br/><br/>


            <section className="mt-2 wallet">
            <Link to="/approvednote" rel="noreferrer" className='text-decoration-none'><div className="container">
                    <Card>
                        <Card.Body className="Justify-content-center d-flex align-items-center text-center m-auto">
                            <p className="Justify-content-center d-flex align-items-center text-center m-auto"><span className='notes'>{approvedNote} Note(s) Uploaded</span></p>
                        </Card.Body>
                    </Card>
                </div></Link>
            </section>

            <section className="mt-4 qtools">
                <div className="container">
                    <div className="row justify-content-center text-center m-auto">
                    <div className="col-6">
                    <Link to="/approvednote" rel="noreferrer" className='text-decoration-none'><Card className="Justify-content-center text-center m-auto">
                                <Card.Body>
                                    <small>Approved Note(s)</small>
                                </Card.Body>
                            </Card></Link>
                        </div>


                       <div className="col-6">
                       <Link to="/rejectednote" rel="noreferrer" className='text-decoration-none'> <Card className="Justify-content-center text-center m-auto">
                                <Card.Body>
                                <small>Pending Note(s)</small>
                                </Card.Body>
                            </Card></Link>
                        </div>


                    </div>
                </div>
            </section>

            <section className="mt-4 dicover">
                <div className="container">
                        
                        <Card className="mb-3 py-3 px-4">
                            <Card.Body>
                                <form method="post" encType="multipart/form-data" onSubmit={uploadNotes}>

                                    <div className='mb-4'>
                                    <label><small>Input course code:</small> </label>
                                        <input type="text" className="form-control py-3 px-4 px-lg-5 mt-3"
                                            placeholder="e.g MTH101, CHM304... etc." value={courseCode} onChange={(e) => setCourseCode(e.target.value)} required/>
                                    </div>

                                    <div className='mb-4'>
                                        <label><small>Input course title:</small> </label>
                                        <input type="text" className="form-control py-3 px-4 px-lg-5 mt-3"
                                            placeholder="e.g Engineering Mathematics... etc." value={courseTitle} onChange={(e) => setCourseTitle(e.target.value)} required/>
                                    </div>

                                    <div className='mb-4'>
                                        <label><small>Select Level:</small> </label>
                                        <select className="form-select form-control py-3 px-4 px-lg-5 mt-3" value={selectedLevels} onChange={(e) => setSelectedLevels(e.target.value)}>
                                        <option>-- Select Level --</option>
                                        {getLevels.map(level => (
                                        <option key={level.id} value={level.id}>
                                            {level.name}
                                        </option>
                                        ))}
                                        </select>
                                    </div>

                                    <div className='mb-4'>
                                        <label><small>Select Semester:</small> </label>
                                        <select className="form-select form-control py-3 px-4 px-lg-5 mt-3" value={courseSem} onChange={(e) => setCourseSem(e.target.value)}>
                                        <option value="">-- Select Semester --</option>
                                        <option value="first">First Semester</option>
                                        <option value="second">Second Semester</option>
                                        </select>
                                    </div>

                                    <div className='mb-4'>
                                        <label><small>Select Institution:</small> </label>
                                        <select className="form-select form-control py-3 px-4 px-lg-5 mt-3" value={selectedInst} onChange={(e) => setSelectedInst(e.target.value)}>
                                        <option>-- Select Institution --</option>
                                        {getInst.map(Inst => (
                                        <option key={Inst.id} value={Inst.id}>
                                            {Inst.name}
                                        </option>
                                        ))}
                                        </select>
                                    </div>

                                    <div className='mb-4'>
                                        <label><small>Select Faculty:</small> </label>
                                        <select className="form-select form-control py-3 px-4 px-lg-5 mt-3" value={selectedFac} onChange={(e) => setSelectedFac(e.target.value)}>
                                        <option>-- Select Faculty --</option>
                                        {getFac.map(Fac => (
                                        <option key={Fac.id} value={Fac.id}>
                                            {Fac.name}
                                        </option>
                                        ))}
                                        </select>
                                    </div>

                                    <div className='mb-4'>
                                        <label><small>Select Department:</small> </label>
                                        <select className="form-select form-control py-3 px-4 px-lg-5 mt-3" value={selectedDept} onChange={(e) => setSelectedDept(e.target.value)}>
                                        <option>-- Select Department --</option>
                                        {getDept.map(Dept => (
                                        <option key={Dept.id} value={Dept.id}>
                                            {Dept.name} - ({Dept.deptcode})
                                        </option>
                                        ))}
                                        </select>
                                    </div>

                                    <div className='mb-4'>
                                    <label><small>Select Notes (PDF only):</small> </label>
                                        <input type="file" className="form-control py-3 px-4 px-lg-5 mt-3" onChange={(e) => setCourseFile(e.target.files[0])} required/>
                                    </div>

                                    <button className="btn text-white mt-3 mb-3 bg-btn w-100 py-3 signupbtnn">Submit</button>
                                    
                                   <ToastContainer />
                                </form>
                            </Card.Body>
                        </Card>

                    
                                    
                </div>
            </section>


            
            <section className="mt-4 dicover">
                    <div className="container">
                    
                            <Card className="mb-3 py-3 px-4">
                                <Card.Body>
                                    <p>Can't find your School, Department or Faculty?</p>
                                </Card.Body>
                                <PopupButton id="hFXSigM3" className="my-buttonn btn text-white mt-2 mb-3 bg-btn w-100 py-3 signupbtnn" type='button'>
                                    Fill your details
                                    </PopupButton>
                            </Card> 
                                        
                    </div>
                </section>


           
           
            <br/><br/><br/><br/><br/><br/>
                
            <section className='nav nav-bottom fixed-bottom'>
                <div className='container'>
                    <div className='row justify-content-center text-center py-lg-3 px-lg-4 py-3'>
                        <div className='col-3'>
                            <Link to="/" rel="noreferrer"><img src={defnav} alt="" className={HomeActive} onClick={homeactive}/></Link>
                        </div>
                        <div className='col-3'>
                            <Link to="/tasks" rel="noreferrer"><img src={dark} alt="" className={UploadActive} onClick={uploadactive}/></Link>
                        </div>

                        <div className='col-3'>
                            <Link to="/wallet" rel="noreferrer"><img src={wallet} alt="" className={walletActive} onClick={walletactive}/></Link>
                        </div>

                        <div className='col-3'>
                            <Link to="/profile" rel="noreferrer"><img src={user} alt="" className={userActive} onClick={useractive}/></Link>
                        </div>
                    </div>
                </div>         
            </section>

           
         </div>

     );
}
 
export default Upload;
import error404error from './img/appimg/loader.gif';
import { Link } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useState } from 'react';

import defnav from './img/ico/blackico/home.svg';
import dark from './img/ico/blackico/book.svg';
import wallet from './img/ico/blackico/wallet-3.svg';
import user from './img/ico/blackico/user.svg';


const Error404 = () => {

    const [HomeActive, setHomeActive] = useState('inactive');
    const [UploadActive, setUploadActive] = useState('inactive');
    const [walletActive, setWalletActive] = useState('inactive');
    const [userActive, setUserActive] = useState('inactive'); 
    


    
    const homeactive = () => {

        setHomeActive('active');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('inactive');
    }


    const uploadactive = () => {

        setHomeActive('inactive');
        setUploadActive('active');
        setWalletActive('inactive');
        setUserActive('inactive');

        
    }


    const walletactive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('active');
        setUserActive('inactive');
    }


    const useractive = () => {

        setHomeActive('inactive');
        setUploadActive('inactive');
        setWalletActive('inactive');
        setUserActive('active');
    }


    return ( 

        <div>

                <HelmetProvider>
                    <Helmet>
                         <title>Page Not Found</title>

                        <meta name="title" content="Page Not Found" />

                        <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate" />
                        <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />

                        <meta itemprop="name" content="Page Not Found" />

                        <meta name="twitter:title" content="Page Not Found" />
                        <meta name="twitter:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                       
                        <meta property="og:title" content="Page Not Found" />
                       
                        <meta property="og:description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                    
                        <meta name="description" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                        <meta name="keywords" content="DotPedia, Academic Aid, PDF, Past Question, CBT. Project, Undergraduate, Graduate " />
                        <meta property="og:title" content="Enjoy access to lecture PDFs, Past Questions, CBT Exams, Project Write up and more. " />
                       
                    </Helmet>
                </HelmetProvider>

        <div className="container col-lg-6">
            <div className="px-4 my-5 justify-content-center text-center pt-1">
                <div className="text-start">
                    <img src={error404error} alt="DotPedia" className="img-fluid" width="200"/>
                    <h1 className="text-white display-1 mb-4 mt-lg-4 mt-3 col-lg-10">Coming Soon!</h1>
                    <p style={{color: "#a1a1a1"}} className="mb-2 col-lg-9">We are working hard to unlock this page/feature soon. Follow us on our social media pages via the username <b className='text-white'>@wearedoteightplus</b> to know when we unlock this page</p>
                    
                </div>
            
            </div>
        </div>


        <br/><br/><br/><br/><br/><br/>
                
                <section className='nav nav-bottom fixed-bottom'>
                    <div className='container'>
                        <div className='row justify-content-center text-center py-lg-3 px-lg-4 py-3'>
                            <div className='col-3'>
                                <Link to="/" rel="noreferrer"><img src={defnav} alt="" className={HomeActive} onClick={homeactive}/></Link>
                            </div>
                            <div className='col-3'>
                                <Link to="/tasks" rel="noreferrer"><img src={dark} alt="" className={UploadActive} onClick={uploadactive}/></Link>
                            </div>
    
                            <div className='col-3'>
                                <Link to="/wallet" rel="noreferrer"><img src={wallet} alt="" className={walletActive} onClick={walletactive}/></Link>
                            </div>
    
                            <div className='col-3'>
                                <Link to="/profile" rel="noreferrer"><img src={user} alt="" className={userActive} onClick={useractive}/></Link>
                            </div>
                        </div>
                    </div>         
                </section>
        </div>


     );
}
 
export default Error404;